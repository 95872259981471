import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { Hidden, AppBar, Toolbar, Grid, Typography, IconButton } from '@material-ui/core';
import { mdiMenu } from '@mdi/js';

import { ShoppingCart } from '../../../components';
import { useScrollPosition, useThemeColor } from '../../hooks';

function AppBarContents({ children, iconColor, handleMenuButtonClick }) {
  const iconCol = useThemeColor(iconColor);
  return (
    <Toolbar>
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item xs={6} md={1}>
          <Typography align="center" component="div">
            <IconButton edge="start" color="inherit" aria-label="Menu" onClick={handleMenuButtonClick}>
              <Icon size={2} path={mdiMenu} title="menu button" color={iconCol} />
            </IconButton>
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs={false} md={10}>
            {children}
          </Grid>
        </Hidden>
      </Grid>
    </Toolbar>
  );
}
AppBarContents.propTypes = {
  children: PropTypes.node.isRequired,
  handleMenuButtonClick: PropTypes.func.isRequired,
  iconColor: PropTypes.oneOf(['primary', 'secondary', 'contrastText'])
};

AppBarContents.defaultProps = { iconColor: null };

function HeroAppbar({ children, bgColor, iconColor, handleMenuButtonClick }) {
  const scrollPosition = useScrollPosition();
  const [slideIn, setSlideIn] = useState(false);

  useEffect(() => {
    if (scrollPosition > 0) {
      setSlideIn(true);
    }

    if (scrollPosition <= 1) {
      setSlideIn(false);
    }
  }, [scrollPosition]);

  return (
    <AppBar position={slideIn ? 'sticky' : 'static'} color={bgColor} elevation={slideIn ? 4 : 0}>
      <AppBarContents iconColor={iconColor} handleMenuButtonClick={handleMenuButtonClick}>
        {children}
      </AppBarContents>
    </AppBar>
  );
}

HeroAppbar.propTypes = {
  children: PropTypes.node.isRequired,
  handleMenuButtonClick: PropTypes.func.isRequired,
  bgColor: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', 'transparent']),
  iconColor: PropTypes.oneOf(['primary', 'secondary', 'contrastText'])
};

HeroAppbar.defaultProps = { bgColor: 'transparent', iconColor: 'secondary' };

export default HeroAppbar;
