import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/styles';

export const useThemeColor = (color) => {
  const theme = useTheme();
  const [iconColor, setIconColor] = useState(theme.palette.primary.contrastText);

  useEffect(() => {
    switch (color.toLowerCase()) {
    case 'primary': {
      setIconColor(theme.palette.primary.main);
      break;
    }
    case 'secondary': {
      setIconColor(theme.palette.secondary.main);
      break;
    }
    case 'contrasttext': {
      setIconColor(theme.palette.primary.contrastText);
      break;
    }

    default: {
      setIconColor(color);
    }
    }
  }, [theme, color, setIconColor]);

  return iconColor;
};
