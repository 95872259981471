import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { buildJSONLDSchema } from './schemas-json-ld';
import { truncateStringSeoFriendly } from '../../helpers/truncate-string';

function _getOrganizationInfo(url, description, legalName, leiCode, slogan, vatID) {
  return {
    '@type': 'Organization',
    url,
    description,
    name: legalName,
    mainEntityOfPage: url,
    sameAs: 'https://www.facebook.com/StarkDynamicsGmbH',
    legalName,
    leiCode,
    slogan,
    vatID
  };
}

function _getBasicInformations(name, legalName, description, url, leiCode, slogan, vatID) {
  return {
    name,
    description,
    url,
    legalName,
    leiCode,
    slogan,
    vatID
  };
}

function _getImageInformations(pageRep, siteUrl, img) {
  if (img == null) return {};
  return {
    '@type': 'ImageObject',
    representativeOfPage: pageRep,
    contentUrl: `${siteUrl}${img.src}`,
    height: img.height,
    width: img.width
  };
}

function _getProductInformations(url, name, description) {
  const currentDate = new Date();
  return {
    itemCondition: ['NewCondition', 'UsedCondition'],
    url,
    offers: {
      '@type': 'Offer',
      url,
      name,
      description,
      acceptedPaymentMethod: ['PayPal', 'Cash', 'ByInvoice', 'ByBankTransferInAdvance'],
      areaServed: 'DE',
      availability: ['InStock'],
      itemCondition: ['NewCondition', 'UsedCondition'],
      priceCurrency: 'EUR',
      price: 'auf Anfrage - Angebote ab 1,50€',
      validFrom: `${currentDate.getFullYear()}-01-01`,
      priceValidUntil: `${currentDate.getFullYear() + 5}-01-01`
    }
  };
}

function SEO({ description, lang, meta, image: metaImage, title, pathnames, keywords, isProductPage, logo }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            keywords
            siteUrl
            title
            titleTemplate
            address {
              addressCountry
              addressLocality
              postalCode
              streetAddress
            }
            contactPoint {
              areaServed
              availableLanguage
              contactType
              email
              hoursAvailable {
                closes
                dayOfWeek
                opens
              }
              telephone
            }
            legalName
            leiCode
            slogan
            vatID
          }
        }
      }
    `
  );
  const metaDescription = description || truncateStringSeoFriendly(site.siteMetadata.description);
  const image = metaImage && metaImage.src ? `${site.siteMetadata.siteUrl}${metaImage.src}` : null;
  const keyW = keywords ? keywords.join(',') : site.siteMetadata.keywords.join(',');

  const JSONLDSchema = useMemo(() => {
    const { siteUrl, address, contactPoint, legalName, leiCode, slogan, vatID } = site.siteMetadata;
    const productInfo = isProductPage ? _getProductInformations(site.siteMetadata.siteUrl, title, metaDescription) : {};

    return buildJSONLDSchema({
      '@context': 'https://schema.org/',
      '@type': isProductPage ? 'Product' : 'Organization',
      logo: { name: `${legalName} Logo`, ..._getImageInformations(false, site.siteMetadata.siteUrl, logo) },
      ..._getBasicInformations(title || site.siteMetadata.title, legalName, metaDescription, siteUrl, leiCode, slogan, vatID),
      address: { '@type': 'PostalAddress', ...address },
      contactPoint: { '@type': 'ContactPoint', ...contactPoint, hoursAvailable: { '@type': 'OpeningHoursSpecification', ...contactPoint.hoursAvailable } },
      image: { ..._getImageInformations(true, site.siteMetadata.siteUrl, metaImage) },
      brand: { ..._getOrganizationInfo(siteUrl, site.siteMetadata.description, legalName, leiCode, slogan, vatID) },
      ...productInfo
    });
  }, [metaDescription, site, metaImage, logo, title, isProductPage]);

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title || site.siteMetadata.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={
        pathnames
          ? pathnames.map((p) => ({
            rel: 'canonical',
            href: `${site.siteMetadata.siteUrl}${p}`
          }))
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `robots`,
          content: `all`
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          name: 'keywords',
          content: keyW
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ]
      .concat(
        metaImage
          ? [
            {
              property: 'og:image',
              content: image
            },
            {
              property: 'og:image:width',
              content: metaImage.width
            },
            {
              property: 'og:image:height',
              content: metaImage.height
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image'
            }
          ]
          : [
            {
              name: 'twitter:card',
              content: 'summary'
            }
          ]
      )
      .concat(meta)}
    >
      <script type="application/ld+json">{JSONLDSchema}</script>
    </Helmet>
  );
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }),
  logo: PropTypes.object.isRequired,
  pathnames: PropTypes.array,
  keywords: PropTypes.array,
  isProductPage: PropTypes.bool
};

SEO.defaultProps = {
  lang: `de`,
  isProductPage: false,
  title: null,
  keywords: null,
  meta: [],
  description: ``,
  image: null,
  pathnames: null
};

export default SEO;
