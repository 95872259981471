import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Img from 'gatsby-image';

import { Box } from '@material-ui/core';

const heroContainerZIndex = 1;

const useStyles = makeStyles((theme) => ({
  heroContainerRoot: { minHeight: '100vh', width: '100%', zIndex: heroContainerZIndex },
  heroContainer: {
    position: 'relative',
    minHeight: '100vh',
    width: '100%',
    zIndex: heroContainerZIndex
  },
  heroContainerContent: { position: 'absolute', zIndex: heroContainerZIndex + 3 },
  heroContainerRootBackDropRoot: {
    position: 'relative',
    height: '100vh',
    width: '100%',
    zIndex: heroContainerZIndex + 1
  },
  heroContainerBackDrop: {
    position: 'absolute',
    zIndex: heroContainerZIndex + 2,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    transition: theme.transitions.create('opacity'),
    opacity: 0.65,
    backgroundColor: theme.palette.text.primary
  },
  contrastText: { backgroundColor: theme.palette.primary.contrastText },
  primary: { backgroundColor: theme.palette.primary.main },
  primaryLight: { backgroundColor: theme.palette.primary.light },
  primaryDark: { backgroundColor: theme.palette.primary.dark },
  secondary: { backgroundColor: theme.palette.secondary.main },
  secondaryLight: { backgroundColor: theme.palette.secondary.light },
  secondaryDark: { backgroundColor: theme.palette.secondary.dark }
}));

function HeroImageBackground(img) {
  if (typeof img === 'string') {
    return <img src={img} alt="" />;
  }

  if (typeof img === 'object') {
    return img.childImageSharp.fluid ? <Img fluid={img.childImageSharp.fluid} /> : <Img fluid={img.childImageSharp.fixed} />;
  }

  return null;
}

function HeroContainer({ children, withImage, img, withBackdrop, backdropColor, ...rest }) {
  const classes = useStyles();
  return (
    <Box className={classes.heroContainerRoot} {...rest}>
      <Box className={classes.heroContainer}>
        {withBackdrop ? (
            <Box className={`${classes.heroContainerBackDrop} ${classes[backdropColor]}`} />
        ) : null}
        {withImage ? HeroImageBackground(img) : null}

        <Box className={classes.heroContainerContent}>{children}</Box>
      </Box>
    </Box>
  );
}

HeroContainer.propTypes = {
  children: PropTypes.node,
  withBackdrop: PropTypes.bool,
  withImage: PropTypes.bool,
  img: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
  backdropColor: PropTypes.oneOf(['contrastText', 'primary', 'primaryLight', 'primaryDark', 'secondary', 'secondaryLight', 'secondaryDark'])
};

HeroContainer.defaultProps = { img: null, withImage: false, children: null, withBackdrop: false, backdropColor: 'contrastText' };

export default HeroContainer;
