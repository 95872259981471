import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router'; // this helps tracking the location

import SEO from '../components/seo';
import AppbarLinks from '../components/appbar-links';
import { HeroAppbar } from '../dream-bit/containers';
import { CookieConsent, Box, Footer, Sidebar } from '../components';
import { toggleDrawer } from '../redux/drawer/actions';
import { useStaticQueries } from '../hooks/static-queries';

const cookieMessage = `
Wir benutzen Cookies, um unsere Seite stetig zu verbessern.
Sie können selbst aussuchen, welche Cookies Sie zulassen möchten.
Für mehr Informationen besuchen Sie bitte unsere Datenschutzseite.
`;

function PageLayout({ children, description, title, lang, meta, image, keywords, pathnames, productPage }) {
  const { logo } = useStaticQueries();
  const location = useLocation();
  const dispatch = useDispatch();
  const { drawerIsOpen } = useSelector(({ drawerReducer }) => drawerReducer);

  return (
    <Box>
      <CookieConsent
        consentId="stark-dynamics-gmbh-cookie-consent"
        location={location}
        bannerTitle="Wir nutzen Cookies."
        askCookies={{ 'stark-dynamics-gmbh-gdpr-google-analytics': 'Google Analytics' }}
        messageBody={cookieMessage}
      />
      <SEO
        logo={logo.childImageSharp.resize}
        description={description}
        title={title}
        lang={lang}
        meta={meta}
        image={image}
        keywords={keywords}
        pathnames={pathnames}
        isProductPage={productPage}
      />
      <HeroAppbar bgColor="primary" handleMenuButtonClick={() => dispatch(toggleDrawer(drawerIsOpen))}>
        <AppbarLinks />
      </HeroAppbar>
      <Sidebar />
      <Box>{children}</Box>
      <Footer />
    </Box>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  productPage: PropTypes.bool,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }),
  pathnames: PropTypes.array,
  keywords: PropTypes.array
};

PageLayout.defaultProps = { lang: `de`, title: null, keywords: null, meta: [], description: ``, image: null, pathnames: null, productPage: false };

export default PageLayout;
