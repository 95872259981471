import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { makeStyles } from '@material-ui/styles';

import { Button } from '../../dream-bit/components';

const links = [
  { path: '/', name: 'Home' },
  { path: '/produkte', name: 'Produkte' },
  { path: '/kontakt', name: 'Kontakt' },
  { path: '/philosophie', name: 'Philosophie' },
  { path: '/ueber-uns', name: 'Über Uns' }
];

const useStyles = makeStyles(({
  appbarLinks: {
    color: 'inherit',
    textDecoration: 'none',
    '& :active': {
      color: 'inherit',
      textDecoration: 'none'
    },
    '& :hover': {
      color: 'inherit',
      textDecoration: 'none'
    },
    '& :visited': {
      color: 'inherit',
      textDecoration: 'none'
    }
  }
  // footerContainer: { minHeight: '50%', backgroundColor: darken(theme.palette.primary.contrastText, 0.3) }
}));

function AppbarLinks() {
  const location = useLocation();
  const classes = useStyles();
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={8}>
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          {links.map((l) => (
            <Grid item key={l.path}>
              <Link className={classes.appbarLinks} to={l.path}>
                <Button styling="roundedCorners" variant={location.pathname === l.path ? 'contained' : 'outlined'} color="secondary" size="small">
                  {l.name}
                </Button>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AppbarLinks;
