/******************************************************************************************
 * Syntax as well as schema examples can be found at https://schema.org/docs/schemas.html *
 * @see https://schema.org/docs/schemas.html                                              *
 ******************************************************************************************/

const _context = { '@context': 'https://schema.org' };

export const buildJSONLDSchema = (jsonld) => {
  const schema = {
    ..._context,
    ...jsonld
  };
  return JSON.stringify(schema);
};
